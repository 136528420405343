import { render, staticRenderFns } from "./SpanFacet.vue?vue&type=template&id=9a062c46&scoped=true&"
import script from "./SpanFacet.vue?vue&type=script&lang=ts&"
export * from "./SpanFacet.vue?vue&type=script&lang=ts&"
import style0 from "./SpanFacet.vue?vue&type=style&index=0&id=9a062c46&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_zcv6irvmnfjwskn3sp4fkgpzee/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a062c46",
  null
  
)

export default component.exports