import { render, staticRenderFns } from "./SystemQuickMetricCard.vue?vue&type=template&id=0d13ecdb&scoped=true&"
import script from "./SystemQuickMetricCard.vue?vue&type=script&lang=ts&"
export * from "./SystemQuickMetricCard.vue?vue&type=script&lang=ts&"
import style0 from "./SystemQuickMetricCard.vue?vue&type=style&index=0&id=0d13ecdb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_zcv6irvmnfjwskn3sp4fkgpzee/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d13ecdb",
  null
  
)

export default component.exports