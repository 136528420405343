import { render, staticRenderFns } from "./GroupsTable.vue?vue&type=template&id=587cd3bb&scoped=true&"
import script from "./GroupsTable.vue?vue&type=script&lang=ts&"
export * from "./GroupsTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_zcv6irvmnfjwskn3sp4fkgpzee/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587cd3bb",
  null
  
)

export default component.exports